import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Grid, GridItem } from '../components'
import { container, padding, bgImage } from '../styles/global'
import { isClient, media } from '../styles/utils'
import { parseACF } from '../utils'
import { getProductTags, getProductTypes } from '../utils/shopify'
import { useMount, useLocation } from 'react-use'
import { forEach, filter, find, remove } from 'lodash'
import useQueryString from 'use-query-string'

const Index = (props) => {
    const graphData = useStaticQuery(query)
    const products = graphData?.allShopifyCollection?.nodes[0]?.products
    const [filters, setFilters] = useState(false)
    const [activeFilters, setActiveFilters] = useState([])
    const [gridData, setGridData] = useState([])
    const location = useLocation()

    const getUrlParams = () => {
        if (isClient()) {
            const filterParam = new URLSearchParams(decodeURI(location?.search)).get('filter')
            if (filterParam === 'merch') {
                setActiveFilters(['T-Shirt', 'Longsleeve T-shirt', 'tote bag'])
            } else if (filterParam === 'vinyl') {
                setActiveFilters(['vinyl 12"', 'vinyl 7"', 'CD'])
            } else {
                setActiveFilters([filters?.[0]])
            }
        }
    }

    useMount(() => {
        setGridData(products)
        setFilters(getProductTypes(products))
        getUrlParams()
    })

    useEffect(() => {
        getUrlParams()
    }, [location])

    useEffect(() => {
        filterGrid()
    }, [activeFilters])

    const filterGrid = () => {
        let results = products

        if (activeFilters.length > 0 && !activeFilters.includes('all')) {
            results = filter(results, item => {
                const tags = getProductTags([item])
    
                let hasType = false
    
                if (activeFilters?.includes(item?.productType)) {
                    hasType = true
                }
    
                return hasType
            })
        }

        setGridData(results)
    }

    const toggleFilter = (filter) => {
        setActiveFilters([filter])
    }

    const renderFilters = () => {
        if (!filters) return

        const items = filters.map((item, i) => {
            const active = activeFilters.includes(item)

            return (
                <Item
                    key={i}
                    className={active && 'active'}
                    onClick={() => {
                        toggleFilter(item)
                    }}
                >
                    {item}
                </Item>
            )
        })

        return (
            <Filters>
                <Label>Filters: </Label>

                {items}

                <Item
                    className={activeFilters.includes('all') && 'active'}
                    onClick={() => {
                        toggleFilter('all')
                    }}
                >
                    all
                </Item>
            </Filters>
        )
    }

    const renderGrid = () => {
        if (!gridData) return

        const items = gridData.map((item, i) => {
            return (
                <GridItem
                    key={i}
                    item={item}
                />
            )
        })

        return (
            <Grid items={items} />
        )
    }
    
	return (
		<Layout
            meta={{
                title: `Shop – Bedroom Suck Records`
            }}
        >
            <Wrapper>
                <Container>
                    <Heading>Shop</Heading>
                    {renderFilters()}
                    {gridData && renderGrid()}
                </Container>
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``
const Label = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout
    
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
    width: 100%;
`

const Container = styled.div`
	${container}
	${padding}

    > ${Heading} {
        display: block;
        margin-top: 29px;
        font-size: 86.8922px;
        line-height: 97.7%;

        ${media.phone`
            font-size: 44px;
            line-height: 97.7%;
        `}
    }
`

// Filters

const Filters = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 22px;

    ${Label}, ${Item} {
        font-family: 'Courier';
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-transform: capitalize;

        ${media.phone`
            font-size: 14px;
        `}

        &:not(:last-child) {
            margin-right: 24px;

            ${media.phone`
                margin-right: 16px;
            `}
        }
    }

    ${Item} {
        cursor: pointer;

        &.active {
            text-decoration: underline;
        }
    }
`

export const query = graphql`
    query {
        allShopifyCollection(filter: {handle: {eq: "bedroom-suck-records"}}) {
            nodes {
                title
                handle
                products {
                    handle
                    title
                    tags
                    productType
                    images {
                        originalSrc
                    }
                    priceRange {
                        minVariantPrice {
                            amount
                            currencyCode
                        }
                    }
                }
            }
        }
    }
`

export default Index